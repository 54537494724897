@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-family: "Khand", "sans-serif";
  }
}
@font-face {
  font-family: longshotRegular;
  src: url(./Assets/fonts/Long_Shot.ttf);
}
.mobile-bar:hover svg {
  stroke: #f6bc18;
}
.main-menu li.active a,
.main-menu li a:hover {
  color: #f6bc18;
}
.pack-trans-img {
  top: -20px;
  right: -20px;
  bottom: 0;
  width: 140px;
}
.detail-list li:last-child {
  border-bottom: 0;
}
.yScroll {
  overflow-y: scroll;
  scrollbar-width: thin;
}
.drawer .menu a span {
  font-size: 12px;
}
.drawer .menu a img {
  width: 40px;
  height: 40px;
  margin-bottom: 3px;
}
.drawer .menu a {
  padding-bottom: 14px;
  padding-left: 10px;
  padding-right: 10px;
}
.drawer .stats {
  padding: 12px 15px;
  grid-template-columns: auto 45px;
  grid-gap: 10px;
}
.drawer .stats div img {
  width: 45px;
}
.drawer .stats p.mb-3 {
  font-size: 14px;
  margin-bottom: 5px;
}
.drawer .stats .text-\[28px\] {
  font-size: 24px;
}
.blk-container {
  border-radius: 20px;
  border: 3px solid #f2e971;
  background: url("./Assets/bg-1.png");
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}
.blk-container > div {
  position: relative;
  z-index: 1;
}

.blk-container::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, #181818 0%, rgba(0, 0, 0, 0) 100%);
  border-radius: 20px;
  box-shadow: 0px 11px 10px 0px rgba(0, 0, 0, 0.1);
}
.blk-container.bg-2 {
  background: url("./Assets/bg-2.png");
}
.blk-container.bg-3 {
  background: url("./Assets/bg-3.png");
  background-position: center;
}
.blk-container.bg-4 {
  background: url("./Assets/bg-4.png");
  background-position: center right;
}
.blk-container.bg-3::after,
.blk-container.bg-4::after,
.blk-container.bg-5::after {
  background: linear-gradient(90deg, #181818 0%, rgba(0, 0, 0, 0.64) 100%);
}
.blk-container.bg-5 {
  background: url("./Assets/bg-5.png");
  background-position: center right;
}
.text-3 {
  background: linear-gradient(
    98deg,
    #42691b 5.82%,
    #42691b 34.02%,
    #42691b 53.05%,
    #42691b 73.49%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: -5px -5px 0px #FCDF8F;
  font-size: 64px;
}
.shadow-1 {
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.6);
}
.lgn-blk {
  background: linear-gradient(
    116deg,
    rgba(35, 35, 32, 0.9) 16.5%,
    rgba(6, 6, 5, 0.9) 24.34%,
    rgba(49, 48, 46, 0.9) 48.24%,
    rgba(0, 0, 0, 0.9) 76.5%,
    rgba(31, 31, 29, 0.9) 83.9%
  );
  box-shadow: 0px 19px 20px 0px rgba(0, 0, 0, 0.3);
}

.tertiary-ttile h2 {
  font-size: 28px;
}
.tertiary-ttile span {
  font-size: 14px;
  line-height: 1.3;
  transform: translateY(2px);
  border-radius: 5px;
}
.table-custom {
  min-width: 768px;
}
.table-custom td {
  color: #fff;
  height: 45px;
}

.text-shadow-one {
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.7);
}
.text-shadow-two {
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.text-shadow-three {
  text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.7);
}
.text-shadow-four {
  text-shadow: 1px 3px 3px rgba(0, 0, 0, 0.25);
}
.triangle-outer {
  width: 105px;
  height: 76px;
}

@media screen and (max-width: 767px) {
  .table-custom-scroller {
    overflow-x: scroll;
  }
}